.sk_chase {
  width: 70px;
  height: 70px;
  position: relative;
  animation: sk_chase 2.5s infinite linear both;
}

.sk_chase_dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk_chase_dot 2s infinite ease-in-out both;
}

.sk_chase_dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #2586c3;
  border-radius: 100%;
  animation: sk_chase_dot_before 2s infinite ease-in-out both;
}

.sk_chase_dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk_chase_dot:nth-child(2) {
  animation-delay: -1s;
}
.sk_chase_dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk_chase_dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk_chase_dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk_chase_dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk_chase_dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk_chase_dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk_chase_dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk_chase_dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk_chase_dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk_chase_dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk_chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk_chase_dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk_chase_dot_before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
